<template>
  <a-card
    :loading="isLoading"
    size="small"
    hoverable
    class="d-flex flex-column flex-grow-1 mt-2"
    :body-style="{
      padding: '0',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }"
  >
    <!-- <template v-if="role == roles.support_user" #title>
      <a-select
        v-model:value="selectedTag"
        :showSearch="true"
        size="small"
        style="width: 47%"
        placeholder="Select tag"
        :filter-option="filterOption"
        :options="tagOptions"
        @search="onSearch"
        @change="onChange"
        :allow-clear="true"
      >
        <a-select-option
          v-if="newlyCreatedTag && newlyCreatedTag.length"
          :key="'new-tag-option'"
          :value="newlyCreatedTag"
        >
          {{ newlyCreatedTag }}
        </a-select-option>
      </a-select>

      <a-select
        v-model:value="selectedStep"
        :showSearch="true"
        size="small"
        style="width: 47%"
        placeholder="Select Step(Optional)"
        :filter-option="filterOption"
        :options="stepsList"
      />
    </template> -->

    <!-- <template v-if="role == roles.support_user" #extra>
      <a-button
        size="small"
        :disabled="!selectedTag"
        :loading="isAddingTags"
        @click="addTimeStampTags"
      >
        Add
      </a-button>
    </template> -->

    <template v-if="role === roles.support_user" #title>
      <div class="tag-selection">
        <a-select
          v-model:value="selectedTag"
          :show-search="true"
          size="small"
          :style="{ width: isModelTag ? '47%' : '94%' }"
          :placeholder="isModelTag ? 'Select Model Tag' : 'Select Video Tag'"
          :filter-option="filterOption"
          :options="tagOptions"
          @search="onSearch"
          @change="onChange"
          allow-clear
        >
          <a-select-option
            v-if="newlyCreatedTag"
            :key="'new-tag-option'"
            :value="newlyCreatedTag"
          >
            {{ newlyCreatedTag }}
          </a-select-option>
        </a-select>

        <a-select
          v-if="isModelTag"
          v-model:value="selectedStep"
          mode="multiple"
          :show-search="true"
          size="small"
          style="width: 47%"
          placeholder="Select Step (Optional)"
          :filter-option="filterOption"
          :options="stepsList"
        />
      </div>
    </template>

    <template v-if="role === roles.support_user" #extra>
      <div
        class="tag-controls d-flex justify-content-center align-items-center"
      >
        <span style="margin-right: 10px">
          {{ isModelTag ? 'Model Tag' : 'Video Tag' }}
        </span>
        <a-switch :checked="isModelTag" @change="handleSwitchChange" />

        <a-button
          size="small"
          type="primary"
          class="mx-2"
          :disabled="
            isModelTag ? !selectedTag || !selectedStep.length : !selectedTag
          "
          :loading="isAddingTags"
          @click="addTimeStampTags"
        >
          Add
        </a-button>
      </div>
    </template>

    <a-list
      v-if="role === roles.support_user"
      class="tags-list"
      size="small"
      item-layout="horizontal"
      :data-source="getTimestamp"
      style="flex-grow: 1; height: 1px; overflow-y: auto"
    >
      <template #renderItem="{ item }">
        <a-list-item @click="handleTagClick(item.timeStamp)">
          <template #actions>
            <a-popconfirm
              :title="`Are you sure you want to detach this tag?`"
              ok-text="Yes"
              cancel-text="No"
              @confirm="removeTimeStampTags(item.id)"
            >
              <a-space class="text-danger">
                <a-tooltip title="Remove">
                  <DeleteOutlined />
                </a-tooltip>
              </a-space>
            </a-popconfirm>
          </template>
          <a-list-item-meta
            :title="`${item.tag.name}${
              item.step_name && ` - ${item.step_name}`
            }`"
            :description="
              role == roles.support_user &&
              `${item.added_by} added ${item.created_at}`
            "
          />
          <div>{{ item.timeStamp }}</div>
        </a-list-item>
      </template>
    </a-list>
  </a-card>
</template>

<script>
import { DeleteOutlined } from '@ant-design/icons-vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timeHelper from 'src/components/shared/Helpers/timeHelper';
import { roles } from 'src/config/roles-config';
import TelemetryService from 'src/services/telemetry';
import VideoService from 'src/services/videos';
import { mapActions, mapGetters } from 'vuex';

dayjs.extend(relativeTime);

export default {
  components: {
    DeleteOutlined,
  },
  inject: ['toast', 'deepClone'],
  props: ['taskRecordId', 'currentVideoTime', 'substepList'],
  emits: ['skipTo'],
  setup() {
    return { roles };
  },
  data() {
    return {
      isModelTag: true, // Default to Model Tag
      selectedStep: [],
      isLoading: false,
      isAddingTags: false,
      selectedTag: null,
      timeStampTags: [],
      newlyCreatedTag: '',
      isCreatingNewTag: false,
    };
  },
  created() {
    // Add keyboard event listener when the component is created
    window.addEventListener('keydown', this.handleKeyToggle);
  },
  beforeUnmount() {
    // Remove keyboard event listener to avoid memory leaks
    window.removeEventListener('keydown', this.handleKeyToggle);
  },
  computed: {
    ...mapGetters([
      'organization',
      'organizationTags',
      'role',
      'tagIdToNameMap',
    ]),
    tagOptions() {
      // Include newly created tag in the options list if it exists
      return [
        ...this.organizationTags
          .filter((data) =>
            this.isModelTag
              ? data.tag_type == 'model'
              : data.tag_type == 'video'
          )
          .map((el) => ({
            value: el.id,
            label: el.tag_name,
          })),
        // Add the newly created tag as an option if it exists
        ...(this.newlyCreatedTag
          ? [
              {
                value: this.newlyCreatedTag,
                label: this.newlyCreatedTag,
              },
            ]
          : []),
      ];
    },
    stepsList() {
      return this.substepList.map((el, index) => ({
        value: el.substepIndex,
        label: `${index + 1} - ${el.name}`,
      }));
    },

    getTimestamp() {
      return this.timeStampTags
        .filter((data) => data.time_stamp)
        .map((el) => ({
          ...el,
          timeStamp: this.convertToMmSs(el.time_stamp),
          created_at: dayjs(new Date(el.created_at)).fromNow(),
        }));
    },
  },
  mounted() {
    this.getTimeStampTags();
    this.fetchOrgTags();
  },
  methods: {
    ...mapActions(['fetchOrganizationTags', 'addNewTag', 'setNewTags']),
    handleKeyToggle(event) {
      if (event.ctrlKey && event.key === 'a') {
        event.preventDefault();
        // Toggle the switch
        this.handleSwitchChange();
      } else if (event.key === ' ') {
        event.preventDefault();
        this.addTimeStampTags();
      }
    },

    handleSwitchChange(checked) {
      // The 'checked' value indicates if the switch is ON (true) or OFF (false)
      this.isModelTag = !this.isModelTag; // Set isModelTag based on the switch state
      this.selectedStep = [];
      this.selectedTag = null;

      // Dynamically update tagOptions based on the selected tag type
      // this.tagOptions = this.isModelTag ? this.modelTagOptions : this.videoTagOptions;
    },
    convertToMmSs(timeStamp) {
      return timeHelper.convertToMMSS(timeStamp?.split('.')[0]);
    },

    fetchOrgTags() {
      if (this.organizationTags?.length) return;
      this.fetchOrganizationTags();
    },

    async getTimeStampTags() {
      this.isLoading = true;
      const [error, data] = await TelemetryService.getTimeStampTags(
        this.taskRecordId
      );
      if (error) {
        this.toast.error('Unable to fetch tags!');
        this.isLoading = false;
        return;
      }
      this.timeStampTags = data
        .filter((data) => data.time_stamp)
        .map((el) => ({
          ...el,
          timeStamp: this.convertToMmSs(el.time_stamp),
          created_at: dayjs(new Date(el.created_at)).fromNow(),
        }));
      this.isLoading = false;
    },

    checkIfTagExist(tag) {
      const currentTime = this.convertToMmSs(
        timeHelper.formatSecondsToHhMmSs(this.currentVideoTime)
      );
      return this.timeStampTags.some(
        (el) =>
          el.tag.id === tag &&
          el.timeStamp === currentTime &&
          this.selectedStep.includes(el.step_index)
      );
    },

    async createNewTag(tagName) {
      const maxLength = 30;
      const validCharsPattern = /^[a-zA-Z0-9 _-]+$/;

      if (tagName.length > maxLength) {
        this.toast.error(`Tag must be ${maxLength} characters or fewer.`);
        return;
      }

      if (!validCharsPattern.test(tagName)) {
        this.toast.error(
          'Tag can only contain letters, numbers, spaces, hyphens (-), and underscores (_).'
        );
        return;
      }

      const payload = {
        tag_name: tagName,
        descript: 'default',
        organization: this.organization,
      };
      const [error, data] = await VideoService.createOrganizationTag(payload);
      if (error) {
        this.toast.error(`Unable to create '${tagName}'`);
        return;
      }
      this.setNewTags({ tag: data.id, tag_value: data.tag_name });
      this.addNewTag(data);
      return data;
    },

    async addTimeStampTags() {
      let isDisabled = this.isModelTag ? !this.selectedTag || !this.selectedStep.length : !this.selectedTag
      if(isDisabled){
        return
      }
      this.isAddingTags = true;
      const responses = await this.createTimeStampTag(this.selectedTag);
      this.isAddingTags = false;

      if (!responses) return;

      this.selectedTag = null;
      this.selectedStep = [];

      this.getTimeStampTags();
    },

    createTimeStampTag(tag) {
      return new Promise(async (resolve) => {
        if (typeof tag === 'string') {
          const newTag = await this.createNewTag(tag);
          if (!newTag) return resolve(false);
          tag = newTag.id;
        }

        if (this.checkIfTagExist(tag)) {
          this.toast.info(
            `${this.tagIdToNameMap[tag]} tag has already been added at this time!`
          );
          return resolve(false);
        }

        if (this.selectedStep.length > 1) {
          let selectedSteps = this.selectedStep.map((step) => {
            let payload = {
              tag: tag,
              ...(this.selectedStep !== null && {
                step_name: this.substepList.find(
                  (data) => data.substepIndex == step
                ).name,
                step_index: step,
              }),
              organization: this.organization,
              task_record: this.taskRecordId,
              time_stamp: timeHelper.formatSecondsToHhMmSs(
                this.currentVideoTime
              ),
              added_by: localStorage.getItem('id'),
            };

            return TelemetryService.addTimeStampTags(payload);
          });

          let resp = await Promise.all(selectedSteps);
          return resolve(true);
        } else {
          const payload = {
            tag: tag,
            ...(this.selectedStep.length == 1 && {
              step_name: this.substepList.find(
                (data) => data.substepIndex == this.selectedStep
              ).name,
              step_index: this.selectedStep[0],
            }),
            organization: this.organization,
            task_record: this.taskRecordId,
            time_stamp: timeHelper.formatSecondsToHhMmSs(this.currentVideoTime),
            added_by: localStorage.getItem('id'),
          };
          let [error] = await TelemetryService.addTimeStampTags(payload);
          if (error) {
            this.toast.error(`Unable to add ${this.tagIdToNameMap[tag]}!`);
            return resolve(false);
          }
          return resolve(true);
        }
      });
    },

    async removeTimeStampTags(id) {
      const tempTags = this.deepClone(this.timeStampTags);
      const itemIndex = tempTags.findIndex((el) => el.id === id);
      this.timeStampTags = this.timeStampTags.filter(
        (_, idx) => idx !== itemIndex
      );

      const [error] = await TelemetryService.removeTimeStampTags(id);
      if (error) {
        this.toast.error('Unable to fetch tags!');
        this.timeStampTags = tempTags;
        return;
      }
    },

    handleTagClick(timeStamp) {
      const timeToSkipTo = timeHelper.formatMmSsToSeconds(timeStamp);
      this.$emit('skipTo', timeToSkipTo);
    },

    filterOption(input, option) {
      return option.label.toLowerCase().includes(input.toLowerCase());
    },

    onSearch(value) {
      const matchingOptions = this.tagOptions.filter((option) =>
        option.label.toLowerCase().includes(value.toLowerCase())
      );

      // Set newlyCreatedTag if no matching options are found
      // this.newlyCreatedTag = value;
    },

    onChange(value) {
      // Reset the flag for creating a new tag if selected
      if (this.newlyCreatedTag.trim().length > 0) {
        this.tagOptions.push({
          value: this.newlyCreatedTag,
          label: this.newlyCreatedTag,
        });
      }
    },
  },
};
</script>
<style>
.tags-list .ant-list-item:hover {
  background: lightgray;
}
</style>
