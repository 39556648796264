import dateHelper from './dateHelper';

export default function updateUrl(object) {
  const dateFormat = 'MM-DD-YY HH:mm:ss';
  const {
    minCycleTime,
    maxCycleTime,
    cyclesFromParams,
    task,
    tags,
    virtualTask,
    devices,
    datetime_start,
    datetime_end,
    start_time,
    end_time,
    missed_steps,
    c_start_date,
    c_end_date,
    c_devices,
    interventions,
    model_version,
  } = object;

  const query = {
    taskId: task,
    deviceId: devices,
    startTime: dateHelper.formatDate(datetime_start, dateFormat),
    endTime: dateHelper.formatDate(datetime_end, dateFormat),
  };

  if (tags) query['tags'] = tags;
  if (minCycleTime) query['minCycleTime'] = minCycleTime;
  if (maxCycleTime) query['maxCycleTime'] = maxCycleTime;
  if (start_time) query['start_time'] = start_time;
  if (end_time) query['end_time'] = end_time;
  if (cyclesFromParams) query['cycles'] = JSON.stringify(cyclesFromParams);
  if (missed_steps) query['missed_steps'] = missed_steps;
  if (interventions) query['interventions'] = interventions;
  if (model_version) query['modelVersion'] = model_version;
  if (virtualTask) query['vt'] = virtualTask;
  if (c_start_date)
    query['c_start_date'] = dateHelper.formatDate(c_start_date, dateFormat);
  if (c_end_date)
    query['c_end_date'] = dateHelper.formatDate(c_end_date, dateFormat);
  if (c_devices) query['c_devices'] = c_devices;

  this.$router.replace({
    name: this.$route.name,
    query,
  });
}
