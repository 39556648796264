<template>
  <div class="mention-editor">
    <QuillEditor
      v-model="content"
      :options="editorOption"
      @ready="onEditorReady"
    />
    <a-button
      :disabled="disableButton"
      @click="handleSubmit"
      class="my-3"
      type="primary"
    >
      Submit
    </a-button>
  </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import Quill from 'quill';
import quillMention from 'quill-mention';
import 'quill-mention/dist/quill.mention.css';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

Quill.register('modules/mention', quillMention);

const atValues = [
  { id: 1, value: 'Fredrik Sundqvist' },
  { id: 2, value: 'Patrik Sjölin' },
];

const hashValues = [
  { id: 3, value: 'Fredrik Sundqvist 2' },
  { id: 4, value: 'Patrik Sjölin 2' },
];

export default {
  name: 'SimpleMentionEditor',
  components: {
    QuillEditor,
  },
  setup(props, { emit }) {
    // Store and state references
    const store = useStore();
    const content = ref('');
    const disableButton = ref(false);
    const quillInstance = ref(null);

    // Getters from Vuex
    const organizationUsers = computed(() => store.getters.organizationUsers);

    // Editor options
    const editorOption = ref({
      theme: 'snow',
      modules: {
        toolbar: false,
        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ['@'],
          source: (searchTerm, renderList, mentionChar) => {
            const values =
              mentionChar === '@' ? organizationUsers.value : hashValues;
            // Filter the values based on the search term
            let filteredValues =
              searchTerm.length === 0
                ? values
                : values.filter((item) =>
                    item.value.toLowerCase().includes(searchTerm.toLowerCase())
                  );

            // Show only the first 10 items
            renderList(filteredValues.slice(0, 10), searchTerm);
          },
          renderItem: (item) => {
            return `<span class="" data-id="${item.id}">${item.value}</span>`;
          },
        },
      },
    });

    // Methods
    const onEditorReady = (quill) => {
      quillInstance.value = quill;
    };

    const handleSubmit = async () => {
      try {
        if (quillInstance.value && !disableButton.value) {
          disableButton.value = true;

          const fullText = quillInstance.value.root.innerHTML;
          if (!fullText) {
            disableButton.value = false;
            return;
          }

          // Extract unique mentions
          const mentions = [];
          const mentionElements =
            quillInstance.value.root.querySelectorAll('span.mention');

          mentionElements.forEach((element) => {
            const name = element.innerText.trim();
            const id = element.getAttribute('data-id');

            if (name && id && !mentions.some((mention) => mention.id === id)) {
              mentions.push({ name, id });
            }
          });

          // Simulating an async operation (e.g., a network request)
          await new Promise((resolve) => setTimeout(resolve, 1000));

          // Emit the comment
          emit('createNewComment', {
            comment: fullText,
            mentions,
          });
        }
      } catch (error) {
        console.error('Error while submitting:', error);
      } finally {
        setTimeout(() => {
          disableButton.value = false;
        }, 1000);
      }
    };

    // Return properties and methods
    return {
      content,
      editorOption,
      onEditorReady,
      handleSubmit,
    };
  },
};
</script>

<style scoped>
.mention-editor {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}
.submit-button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.submit-button:hover {
  background-color: #45a049;
}
/* Hide the toolbar */
:deep(.ql-toolbar) {
  display: none;
}
</style>
