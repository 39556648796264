<template>
  <a-row :gutter="[8, 8]" class="h-100">
    <a-col span="10" class="d-flex flex-column">
      <!-- Video -->
      <a-card
        size="small"
        hoverable
        class="d-flex flex-column"
        style="height: 50%"
        :body-style="{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '8px',
          height: '100%',
        }"
      >
        <a-spin v-if="isFetchingVideoUrl" size="large" class="m-auto" />

        <!-- Video -->
        <div
          v-else-if="video?.isVideoExists"
          style="position: relative"
          class="w-100 h-100"
        >
          <div
            v-if="!showVideoControls"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.3);
              z-index: 1;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <a-spin size="large" />
          </div>

          <a-dropdown :trigger="['click']" placement="bottomRight">
            <MoreOutlined class="video-options background-animation" />
            <template #overlay>
              <a-menu>
                <a-popconfirm
                  :title="`Are you sure you want to mark this as a ${
                    !traceCycleData?.is_good_cycle ? 'Good' : 'Bad'
                  } cycle?`"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="toggleCycleStatus()"
                >
                  <a-menu-item>
                    <a-space>
                      <ToolOutlined />
                      <span>
                        {{ `Mark as ${cycleType}` }}
                      </span>
                    </a-space>
                  </a-menu-item>
                </a-popconfirm>
                <!-- <a-menu-item v-if="role == roles.support_user"> -->

                <a-popconfirm
                  title="Are you sure you want to archive this cycle?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="archiveCycle(video)"
                >
                  <a-menu-item v-if="role == roles.support_user">
                    <a-space>
                      <DeleteOutlined />
                      <span> Archive cycle </span>
                    </a-space>
                  </a-menu-item>
                </a-popconfirm>
              </a-menu>
            </template>
          </a-dropdown>
          <video
            id="trace-page-single-video-play"
            ref="video"
            class="w-100 object-fit-contain border rounded h-100"
            controls
            :src="video?.fileURL"
            @play="onPlay"
            @seeking="handleSeeking"
            @ended="onEnd"
            @loadeddata="onLoad"
          />
        </div>

        <!-- Untraced Cycle -->
        <div v-else class="w-100 d-flex" style="height: 40vh !important">
          <a-result title="Untraced Cycle!" class="m-auto">
            <template #icon>
              <exclamation-circle-outlined class="text-danger" />
            </template>
          </a-result>
        </div>
      </a-card>

      <!-- Timestamp Tags -->
      <timestamp-tags
        v-if="video?.isVideoExists"
        :task-record-id="video.id"
        :current-video-time="currentVideoTime"
        :substepList="substepList"
        @skipTo="skipTo"
      />
    </a-col>

    <a-col span="8">
      <!-- Steps List -->
      <steps-list
        :steps-list="stepsList"
        :negative-steps-list="negativeSteps"
        :optional-steps-list="optionalSteps"
        :missed-steps-list="missedSteps"
        :background-time="backgroundTime"
        :current-step-list="currentStepList"
        :cycle="currentCycle"
        :detected-steps="detectedSteps"
        :steps-time="stepsTime"
        :is-task-details-loading="isFetchingTaskDetails"
        :is-prediction-file-not-exist="isPredictionFileNotExist"
        :steps-translation-mapping="stepsTranslationMapping"
        :process-details="processDetails"
        :cycle-time="cycleTime + backgroundTime"
        :propagated-steps="propagatedSteps"
        @handleClickSubStep="handleClickSubStep"
      />
    </a-col>

    <a-col span="6" class="d-flex flex-column">
      <!-- Missed Steps -->
      <missed-steps
        :video="video"
        :missed-steps="!isGoodCycle ? missedSteps : []"
        :title="'Missed Steps'"
        :steps-translation-mapping="stepsTranslationMapping"
        :is-task-details-loading="isFetchingTaskDetails"
      />

      <a-card
        v-if="video?.isVideoExists"
        size="small"
        hoverable
        class="h-50 d-flex flex-column mt-2"
        style="flex: 60%"
        :body-style="{ height: '1px', flex: '1 1 auto', overflowY: 'auto' }"
        :tab-list="tabsList"
        :active-tab-key="currentTab"
        @tabChange="(key) => (currentTab = key)"
      >
        <!-- Cycle Meta -->
        <div v-if="currentTab === 'meta'">
          <a-descriptions v-if="isMetaExist" :column="1" bordered>
            <a-descriptions-item
              v-for="(value, key) in traceCycleData?.metadata"
              :key="key"
              :label="key"
              :label-style="{
                textTransform: 'capitalize',
              }"
            >
              {{ value }}
            </a-descriptions-item>
          </a-descriptions>
          <a-list />
        </div>

        <!-- Comments -->
        <comments
          v-else-if="currentTab === 'comments'"
          :comments="comments"
          :selected-video="video"
          :is-minimized="true"
          :is-fetching-video-url="isFetchingVideoUrl"
          @updateVideoListCount="updateVideoListCount"
          @updateVideo="(args) => $emit('updateVideo', args)"
        />
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
  ToolOutlined,
} from '@ant-design/icons-vue';
import axios from 'axios';
import MissedSteps from 'src/components/user-panel/pages/DeployModel/components/MissedStep';
import StepsList from 'src/components/user-panel/pages/DeployModel/components/StepsList';
import Comments from 'src/components/user-panel/pages/trace/Comments.vue';
import { roles } from 'src/config/roles-config';
import TelemetryService from 'src/services/telemetry';
import { mapActions, mapGetters } from 'vuex';
import TimestampTags from './TimestampTags.vue';
export default {
  name: 'RecordedInference',
  components: {
    ToolOutlined,
    DeleteOutlined,
    MoreOutlined,
    MissedSteps,
    StepsList,
    Comments,
    ExclamationCircleOutlined,
    TimestampTags,
  },
  inject: ['toast'],
  props: {
    navigate: { type: Boolean, default: false },
    isFetchingVideoUrl: { type: Boolean, default: false },
    video: { type: Object, default: () => ({}) },
    stepsList: { type: Array, default: () => [] },
    comments: { type: Array, default: () => [] },
  },
  emits: [
    'loading',
    'updateVideoListCount',
    'updateVideo',
    'toggleCycleStatus',
    'archivedCycleCount',
  ],

  data() {
    return {
      roles,
      isGoodCycle: false,
      frame: null,
      interval: null,
      currentStepList: [],
      currentCycle: 1,
      filePreds: null,
      stepsStartPoints: {},
      stepsTime: {},
      substepList: [],
      backgroundTime: 0,
      missedSteps: [],
      detectedSteps: {},
      isPredictionFileNotExist: false,
      processDetails: {},
      showVideoControls: false,
      propagatedSteps: [],
      stepsLegends: [
        { label: 'Completed Steps', background: 'list-group-item-success' },
        { label: 'Current Step', background: 'list-group-item-warning' },
        { label: 'Missed Steps', background: 'list-group-item-danger' },
        { label: 'Propagated Steps', background: 'list-group-item-secondary' },
      ],
      traceCycleData: null,
      currentVideoTime: 0,
      currentTab: 'meta',
      tabsList: [
        {
          key: 'meta',
          tab: 'Meta',
        },
        {
          key: 'comments',
          tab: 'Comments',
        },
      ],
      missedStepsFromTelemetry: [],
      cycleMeta: {},
      cycleTime: 0,
      taskId: null,
      historyId: null,
    };
  },

  computed: {
    ...mapGetters([
      'negativeSteps',
      'optionalSteps',
      'taskStepsCount',
      'taskJson',
      'isFetchingTaskDetails',
      'stepsTranslationMapping',
      'role',
    ]),

    cycleType() {
      return !this.traceCycleData?.is_good_cycle ? 'Good' : 'Bad';
    },

    isMetaExist() {
      return (
        this.traceCycleData?.metadata &&
        Object.keys(this.traceCycleData?.metadata).length
      );
    },
  },

  watch: {
    video: {
      handler(newValue, oldValue) {
        if (newValue?.cycle_id !== oldValue?.cycle_id) {
          this.handleFetchRequiredData();

          const steps = this.video.missed_steps?.split(', ');
          if (!steps.length) return;
          this.missedStepsFromTelemetry = steps.map((s) =>
            s ? Number(s) : ''
          );
        }
        this.updateMissedSteps();
      },
      deep: true,
    },

    frame(currentFrame) {
      this.currentStepList =
        this.filePreds[currentFrame]?.['pred']?.filter(
          (el) => !this.missedStepsFromTelemetry?.includes(el)
        ) || [];
      this.updateSubstepTimes();
    },

    stepsList(list) {
      this.substepList = list.filter((el) => el.type === 'sub-step');
    },

    currentStepList(list) {
      list.forEach(this.addStepInDetectedStepsDict);
    },

    currentCycle() {
      this.detectedSteps = {};
      this.stepsTime = {};
    },
  },

  mounted() {
    const tempStepsList = this.stepsList.filter((el) => el.type === 'sub-step');
    this.substepList = tempStepsList;
    this.setStepsStartTime(tempStepsList);
    this.handleFetchRequiredData();
  },

  beforeUnmount() {
    this.resetState();
    clearInterval(this.interval);
  },

  methods: {
    ...mapActions(['fetchTaskDetails']),
    updateVideoListCount(videoIndex, commentLength) {
      this.$emit('updateVideoListCount', videoIndex, commentLength);
    },
    setStepsStartTime(stepsList = this.substepList) {
      this.stepsTime = stepsList.reduce((res, el) => {
        res[el.substepIndex] = 0;
        return res;
      }, {});
    },

    async toggleCycleStatus() {
      let isGoodCycle = !this.traceCycleData.is_good_cycle;
      this.traceCycleData.is_good_cycle = isGoodCycle;
      this.isGoodCycle = isGoodCycle;
      this.updateMissedSteps();
      this.$emit('toggleCycleStatus', { video: this.video, isGoodCycle });
    },

    async archiveCycle(video) {
      await TelemetryService.archiveCycle(video.cycle_id);
      this.emitter.emit('getAnalyticsAndTrace', true);
      this.$emit('archivedCycleCount', video.is_good_cycle);
      if (this.navigate) {
        this.$router.push('/');
      }
    },

    updateSubstepTimes(frame = this.frame) {
      const stepsObj = this?.filePreds[frame];
      if (!stepsObj) return;
      stepsObj.pred = stepsObj?.pred.filter(
        (el) => !this.missedStepsFromTelemetry?.includes(el)
      );

      const tempStepsTime = { ...this.stepsTime };

      const { pred, times } = stepsObj;
      if (pred[0] === this.taskStepsCount) {
        this.backgroundTime = times[0] || 0;
        return;
      }
      this.cycleTime += times[0] - this.stepsTime[pred[0]];
      pred.forEach((step, index) => {
        tempStepsTime[step] = times[index];
      });
      this.stepsTime = tempStepsTime;
    },

    async onLoad() {
      this.showVideoControls = true;
    },

    onPlay() {
      const self = this;

      if (!self.isPredictionFileNotExist)
        this.interval = setInterval(
          self.updateFrames,
          1000 / (this.video?.fps || 30) / 2
        );
    },

    onEnd() {
      this.cycleTime = 0;
      this.backgroundTime = 0;
      this.detectedSteps = {};
      this.currentCycle = 1;
      this.currentStepList = [];
      clearInterval(this.interval);
    },

    addStepInDetectedStepsDict(step) {
      const temp = { ...this.detectedSteps };
      if (this.missedStepsFromTelemetry?.includes(step)) temp[step] = false;
      else temp[step] = true;
      this.detectedSteps = temp;
    },

    updateFrames() {
      try {
        const currentTime = this.$refs?.video?.currentTime.toFixed(5);
        this.currentVideoTime = currentTime;
        const num = currentTime * (this.video?.fps || 30);

        this.frame = Math.floor(num);
      } catch (error) {
        console.log('update frame error', error);
        this.resetState();
      }
    },

    async handleFetchRequiredData() {
      const { traceId } = this.$route.params;
      const { cycle_id } = this.$route.query;
      let query;
      if (traceId && cycle_id) {
        query = { cycle_id, task_record_id: traceId };
      } else {
        query = {
          cycle_id: this.video?.cycle_id,
          task_record_id: this.video?.id,
        };
      }

      if (query.cycle_id && query.task_record_id) {
        await this.handleGetTraceRecordData(query);
        await this.fetchTaskDetails({
          taskId: Number(this.taskId),
          historyId: this.historyId,
        });
      }

      this.updatePredictions();
      this.updateMissedSteps();
    },

    async handleGetTraceRecordData(query) {
      if (this.video?.cycleData) {
        this.traceCycleData = this.video.cycleData;
        this.isGoodCycle = this.video.cycleData?.is_good_cycle;
        return;
      }
      const [error, data] = await TelemetryService.getTraceCycleRecordData(
        query
      );
      if (error) {
        console.log('Error in getting trace record');
        return;
      }
      this.traceCycleData = data.cycle;
      this.isGoodCycle = data?.cycle?.is_good_cycle;
      if (data?.cycle?.history_id) {
        this.historyId = data.cycle.history_id;
      }
      if (data?.task_record?.Task) {
        this.taskId = data?.task_record?.Task;
      }
    },

    updateMissedSteps() {
      if (!this.video || this.isGoodCycle) return;
      this.missedSteps = this.video.missedStepsName;
    },

    async updatePredictions() {
      if (!this.video) return;
      const { per_frame_prediction_file_url } = this.video;

      try {
        const { data } = await axios.get(per_frame_prediction_file_url);
        const { preds, propagated_steps } = data;
        this.setStepStartPoint(preds);
        this.propagatedSteps = propagated_steps || [];
        this.filePreds = preds;
      } catch (error) {
        console.log('error', error);
        this.isPredictionFileNotExist = true;
        this.filePreds = null;
        this.detectedSteps = {};
      }
    },

    handleSeeking(e) {
      const currentTime = this.$refs?.video?.currentTime.toFixed(5);
      this.currentVideoTime = currentTime;
      if (this.isPredictionFileNotExist) return;
      this.setStepsStartTime();
      this.cycleTime = 0;
      const currentFrame = Math.floor(e.target.currentTime * this.video.fps);
      for (let i = 0; i <= currentFrame; i++) {
        this.updateSubstepTimes(i);
      }
    },

    setStepStartPoint(preds) {
      var tempStepStartTime = {};
      Object.entries(preds).forEach(([frame, value]) => {
        value.pred.forEach((key) => {
          if (!(key in tempStepStartTime)) tempStepStartTime[key] = frame;
        });
      });
      this.stepsStartPoints = tempStepStartTime;
    },

    handleClickSubStep(stepIndex) {
      if (!(stepIndex in this.stepsStartPoints)) return;
      const frame = this.stepsStartPoints[stepIndex];
      this.$refs.video.currentTime = frame / this.video.fps;
    },

    resetState() {
      this.frame = 0;
      this.currentStepList = [];
      this.currentCycle = 1;
      this.detectedSteps = {};
      this.isPredictionFileNotExist = false;
      this.stepsTime = {};
      clearInterval(this.interval);
    },

    skipTo(timeInSec) {
      this.$refs.video.currentTime = timeInSec;
    },
  },
};
</script>

<style scoped>
.video-options {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: large;
  z-index: 1;
  padding: 7px;
  transition: all;
}

.video-options:hover {
  color: white;
  background: #a0a0a0;
  border-radius: 50%;
}

/* @-o-keyframes fadeIt {
  0% {
    background-color: #ffffff;
  }
  50% {
    background-color: #ad301b;
  }
  100% {
    background-color: #ffffff;
  }
}
@keyframes fadeIt {
  0% {
    background-color: #ffffff;
  }
  50% {
    background-color: #ad301b;
  }
  100% {
    background-color: #ffffff;
  }
}

.background-animation {
  -o-animation: fadeIt 5s ease-in-out;
  animation: fadeIt 5s ease-in-out;
} */
</style>
